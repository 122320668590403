.header {
  padding: 20px 20px 10px 20px;
  margin-bottom: 0px;
}

.title {
  font-size: 26px;
}

.box {
  &:hover {
    background-color: var(--mantine-color-secondary);
  }
}
