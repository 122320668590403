.base {
  text-transform: uppercase;
  font-family: var(--mantine-font-family-monospace);
}

.active {
  composes: base;
  position: relative;
  
  &::before {
    content: '';
    border: 1px solid var(--mantine-primary-color-filled);
    width: 8px;
    height: 1.5rem;
    display: inline-block;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    transform: translate(-20px, 0);
    transition: all ease .2s;
    opacity: 1;
    position: absolute;
  }
  &::after {
    content: '';
    border: 1px solid var(--mantine-primary-color-filled);
    width: 8px;
    height: 1.5rem;
    display: inline-block;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    transform: translate(-2px, 0);
    transition: all ease .2s;
    opacity: 1;
    position: absolute;
    right: 0;
  }
}

.hover {
  composes: base;
  position: relative;

  &:hover {
    background-color: transparent;
    color: light-dark(lighten(var(--mantine-color-black), 10%), var(--mantine-primary-color-filled));

    &::before {
      opacity: 1;
      transform: translate(-20px, 0);
    }
    &::after {
      opacity: 1;
      transform: translate(-2px, 0);
    }
  }
  
  &::before {
    content: '';
    border: 1px solid var(--mantine-primary-color-filled);
    width: 8px;
    height: 1.5rem;
    display: inline-block;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    transform: translate(-25px, 0);
    transition: all ease .2s;
    opacity: 0;
    position: absolute;
    transition: all ease .3s;
  }
  &::after {
    content: '';
    border: 1px solid var(--mantine-primary-color-filled);
    width: 8px;
    height: 1.5rem;
    display: inline-block;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    transform: translate(5px, 0);
    transition: all ease .2s;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: all ease .3s;
  }
}


.label {
  font-weight: bold;
  line-height: 0px;
  font-family: var(--mantine-font-family-monospace);
}
