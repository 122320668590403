.rightSections {
  width: fit-content;
}

.rightButtonColor {
  color: light-dark(var(--mantine-color-texts), var(--mantine-color-white));
}

.rightButtonsRoot {
  height: 32px;
}

.label {
  font-size: 14px;
}

.buttonOutline {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-action));
  border-color: var(--mantine-color-action);
  outline-offset: 2px;
  border: 0;
  border-radius: 4px;
  outline: 1px solid var(--mantine-color-action);
  text-transform: uppercase;
  background-color: var(--mantine-color-outlineButton);
  transition: all ease .3s;
  font-family: var(--mantine-font-family-monospace);


  &:not([data-disabled]) {
    @mixin hover {
      background-color: var(--mantine-color-action) !important;
      color: var(--mantine-color-black);
      transition: all ease .3s;
    }
  }
}
