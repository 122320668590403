.button {
  padding: 8px 20px;
  border-radius: 8px;
  &:not([data-disabled]) {
    @mixin hover {
      background-color: var(--mantine-color-secondary);
    }
  }
}

.selected {
  color: var(--mantine-color-black);
  background-color: var(--mantine-primary-color-filled);
  &:not([data-disabled]) {
    @mixin hover {
      background-color: var(--mantine-primary-color-filled);
    }
  }
}

.qrWrapper {
  border: 1px solid var(--mantine-color-greyBorder);
  border-radius: 8px;
}
