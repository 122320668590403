.group {
  border: 1px solid var(--mantine-color-greyBorder);
  border-radius: var(--mantine-radius-md);
  padding: 4px;
  background-color: transparent;
}

.text {
  display: inline-block;
}

.placeholder {
  color: var(--mantine-color-placeholder);
}

.rightText {
  display: "inline-block";
  color: var(--mantine-color-texts);
}
