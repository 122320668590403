.root {
  width: 100%;
}

.button {
  display: block;
  color: var(--mantine-color-texts);
  border: 1px solid var(--mantine-color-greyBorder);
  height: 54px;
  border-radius: var(--mantine-radius-md);
  width: 100%;
  padding-left: var(--mantine-spacing-sm);
  padding-right: var(--mantine-spacing-xs);
  &:not([data-disabled]) {
    @mixin hover {
      background-color: light-dark(
        var(--mantine-color-gray-0),
        var(--mantine-color-dark-4)
      );
    }
  }
}

.addButtonVisible {
  display: block;
  visibility: visible;
}

.addButtonHidden {
  display: none;
  visibility: hidden;
}

.selected {
  border: 1px solid var(--mantine-primary-color-filled);
}
