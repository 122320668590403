.navbar {
  top: 0px;
  background-color: var(--mantine-color-navbarBackground);
  @mixin light {
    border-right: 1px solid var(--mantine-color-black);
  }
}

@media (max-width: 48em) {
  .navbar {
    background-color: var(--mantine-color-whiteDark);
  }
}

.scrollable {
  overscroll-behavior: contain;
  min-height: calc(100vh - 304px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--mantine-primary-color-filled);
  }
}
