.button {
  background-color: transparent;
}
.button:not([data-disabled]):hover {
  background-color: transparent;
}

.root {
  height: 32px;
}
