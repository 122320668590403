.openWalletsButton {
  height: 52px;
  background-color: light-dark(var(--mantine-color-navbarBackground), var(--mantine-color-black));
  border: 1px solid light-dark(var(--mantine-color-dark-4), var(--mantine-color-gray-2));
  &:not([data-disabled]) {
    @mixin hover {
      background-color: var(--mantine-color-whiteDark-hover);
    }
  }
}

.openWalletIcon {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-texts));
}

.dropdown {
  padding: 0px;
  padding-top: var(--mantine-spacing-md);
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-black));
  border: 1px solid var(--mantine-color-whiteDark) !important;
}

.clickableText {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-texts);
  &:not([data-disabled]) {
    @mixin hover {
      text-decoration: underline;
      text-decoration-color: var(--mantine-color-action);
    }
  }
}
