.icon {
  background-color: var(--mantine-color-whiteDark);
  &:not([data-disabled]) {
    @mixin hover {
      transform: none !important;
      background-color: var(--mantine-color-whiteDark-hover);
    }
  }
  &:active {
    transform: translateY(3px) !important;
  }
}
