html {
  height: auto;
}
body {
  background: light-dark(var(--mantine-color-gray-1), var(--mantine-color-black));
  color: var(--mantine-color-texts);
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--mantine-primary-color-filled);
  }
}
