@font-face {
  font-family: 'SpaceMonoNumbers';
  src: url('../../fonts/font-space-mono-regular.woff2') format('woff2');
  unicode-range: U+0030-0039;
}

@font-face {
  font-family: 'BananaGrotesk';
  src: url('../../fonts/font-banana-grotesk-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BananaGrotesk';
  src: url('../../fonts/font-banana-grotesk-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

.mantine-focus-auto {
  font-family: 'SpaceMonoNumbers' !important;
}

.actionIcon {
  color: light-dark(var(--mantine-color-dark-4), var(--mantine-color-gray-2));
  transition-duration: 0.3s;
  transition-property: transform;
  &:hover {
    color: light-dark(var(--mantine-color-dark-4), var(--mantine-color-gray-2));
    background-color: transparent;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }

  &[data-loading] {
    --_ai-loading-overlay-bg: transparent;
  }
}

.actionIcon[data-variant="transparent"] {
  border: 0;
  &:disabled,
  &[data-disabled] {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-disabled));
    background-color: transparent;
  }
}

.actionIcon[data-variant="outline"] {
  border: 1px solid light-dark(var(--mantine-color-dark-4), var(--mantine-color-gray-2));
  background-color: transparent;
}

.navLinkRoot {
  line-height: 18px;
  padding: var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
  color: var(--mantine-color-texts);
  height: 40px;
  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      transparent
    );
    color: light-dark(var(--mantine-color-gray-8), var(--mantine-color-gray-0));
  }
}
/* .navLinkLabel {
  font-family: var(--mantine-font-family-title);
} */

.button {
  color: black;
  background-color: var(--mantine-color-secondary);
  &:not([data-disabled]) {
    @mixin hover {
      background-color: var(--mantine-color-secondary-hover);
    }
  }
  &:disabled,
  &[data-disabled] {
    color: var(--mantine-color-placeholder);
    background-color: var(--mantine-color-disabled);
  }
}

.popover {
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-black));
  @mixin dark {
    border-radius: var(--mantine-radius-md);
    border-color: var(--mantine-primary-color-filled);
    border-width: 4px;
    border-style: solid;
  }
}

.modalHeader {
  background-color: transparent;
}
.modalTitle {
  color: var(--mantine-color-texts);
  font-weight: 700;
  font-family: var(--mantine-font-family-title);
}

.modalContent {
  @mixin dark {
    border-radius: var(--mantine-radius-md);
    border-color: var(--mantine-primary-color-filled);
    border-width: 1px;
    border-style: solid;
  }
}
.modalInner {
  backdrop-filter: blur(5px);
}

.timelineBullet {
  background-color: var(--mantine-color-whiteDark);
}

.divider {
  border-left-color: light-dark(
    var(--mantine-color-dark-8),
    var(--mantine-color-gray-4)
  ) !important;
}

.table {
  thead {
    tr {
      border: none;
      border-bottom: 1px solid #4D4D4D;
    }
  }
  tbody {
    tr {
      border: none;
      border-bottom: 1px solid #4D4D4D;
      td {
        --text-fz: var(--mantine-font-size-sm);
      }
    }
  }
}

.paper {
  border-color: var(--mantine-color-greyBorder);
  color: inherit;
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-black));
}

.paginationControl {
  border: 1px solid
    var(--mantine-color-greyBorder);
  border-radius: 4px;
  background-color: transparent;
  &[data-active] {
    color: light-dark(
      var(--mantine-color-black),
      var(--mantine-primary-color-filled)
    );
    border-color: var(--mantine-primary-color-filled);
  }
}

.stepDescription {
  color: var(--mantine-color-placeholder);
}
.stepIcon {
  color: light-dark(
    var(--mantine-color-black),
    var(--mantine-color-secondaryTexts)
  );
  @mixin light {
    border-color: var(--mantine-color-dark-2);
    background-color: var(--mantine-color-dark-2);
  }

  &[data-progress],
  &[data-completed="true"] {
    color: var(--mantine-color-black);
    border-color: var(--mantine-primary-color-filled);
    background-color: var(--mantine-primary-color-filled);
  }
}
.stepperVerticalSeparator {
  @mixin light {
    border-color: var(--mantine-color-dark-2);
  }
}

.input {
  border: 1px solid var(--mantine-color-dark-2);
  background-color: transparent;
}

.textInput {
  &::placeholder {
    color: var(--mantine-color-placeholder);
  }
}

.anchor {
  color: var(--mantine-color-texts);
  &:hover {
    text-decoration-color: var(--mantine-color-action);
  }
}

.comboboxDropdown {
  background-color: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-9));
}

.comboboxOption {
  @mixin hover {
    background-color: var(--mantine-color-whiteDark-hover);
  }
}

.scrollbarThumb {
  background-color: var(--mantine-primary-color-filled) !important;
}
