.button {
  color: var(--mantine-color-black);
  background-color: var(--mantine-color-action);
  &:not([data-disabled]) {
    @mixin hover {
      color: var(--mantine-color-black);
      background-color: var(--mantine-color-action-hover);
    }
  }
}
.buttonOutline {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-action));
  border-color: var(--mantine-color-action);
  outline-offset: 2px;
  border: 0;
  border-radius: 4px;
  outline: 1px solid var(--mantine-color-action);
  text-transform: uppercase;
  background-color: var(--mantine-color-outlineButton);
  transition: all ease .3s;
  font-family: var(--mantine-font-family-monospace);

  &:not([data-disabled]) {
    @mixin hover {
      background-color: var(--mantine-color-action) !important;
      color: var(--mantine-color-black);
      transition: all ease .3s;
    }
  }

  &:disabled,
  &[data-disabled] {
    outline-color: var(--mantine-color-placeholder);
    color: var(--mantine-color-placeholder);
    background-color: var(--mantine-color-disabled);
  }
}
