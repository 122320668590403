.button {
  height: 42px;
  width: 212px;
  border-color: var(--mantine-color-greyBorder);
  background-color: transparent;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  &:not([data-disabled]) {
    @mixin hover {
      color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
      background-color: light-dark(
        var(--mantine-color-gray-0),
        var(--mantine-color-dark-9)
      );
    }
  }
}

.disabledIcon {
  filter: grayscale(100%);
}

.inner {
  justify-content: start;
}

.label {
  width: 100%;
  font-weight: normal;
}

.selected {
  border: 1px solid var(--mantine-primary-color-filled);
}

.rightIcon {
  color: var(--mantine-color-texts);
  font-weight: 500;
}
