.footer {
  background-color: light-dark(var(--mantine-color-white),var(--mantine-color-moonDark-5));
  border-top: 1px solid light-dark(var(--mantine-color-black),#4D4D4D);
}
.divider { height: 20px };
.terms:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: var(--mantine-color-action);
}
